import React, { useState } from "react";
import { Button, Fab, IconButton, Modal, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { addLastOrderToCart, clearLastOrder } from "../redux/cartSlice";
import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import ReceiptIcon from '@mui/icons-material/Receipt';

const LastOrder: React.FC = () => {
    const dispatch = useDispatch();
    const lastOrder = useSelector((state: RootState) => state.cart.lastOrder);

    const [modalOpen, setModalOpen] = useState(false);

    const handleAddToCart = () => {
        dispatch(addLastOrderToCart());
        setModalOpen(false);
    };

    const handleClearLastOrder = () => {
        dispatch(clearLastOrder());
        setModalOpen(false);
    };

    const calculateTotalAmount = () => {
        return lastOrder.reduce((total, item) => total + item.price * item.quantity, 0);
    };

    return (
        <>
            {lastOrder && lastOrder.length > 0 && (
                // <Button
                //     variant="outlined"
                //     onClick={() => setModalOpen(true)}
                //     sx={{
                //         position: "fixed",
                //         bottom: "20px",
                //         left: "20px",
                //         zIndex: 9999,
                //     }}
                // >
                //     Ваш прошлый заказ
                // </Button>
                <div className="fixed left-2 bottom-5 flex justify-center z-[1001]">
                    <Fab
                        variant="extended"
                        onClick={() => setModalOpen(true)}
                    >
                        <ReceiptIcon />
                    </Fab>
                </div>
            )}
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="last-order-modal"
                aria-describedby="last-order-description"
            >

                <Box

                    className="bg-modal flex flex-col justify-center rounded-2xl"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        boxShadow: 24,
                        p: 4,
                        width: { xs: '90%', sm: 'auto' },
                    }}
                >
                    <IconButton
                        sx={{ position: "absolute", top: 0, right: 0 }}
                        onClick={() => setModalOpen(false)}
                    >
                        <Close />
                    </IconButton>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                        <Typography className="text-center w-full" variant="h6" id="last-order-modal">
                            Ваш последний заказ
                        </Typography>
                    </div>
                    {lastOrder && lastOrder.map((item) => (
                        <div key={item.id}>
                            <Typography>{item.quantity} * {item.name}, {item.price} за шт</Typography>
                        </div>
                    ))}
                    <Typography className="text-center">
                        На общую сумму: {calculateTotalAmount()} лек
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            color: "black",
                            fontWeight: 700,
                            mt: 2,
                            backgroundColor: "#FFAA90",
                            "&:hover": {
                                backgroundColor: "#E89F50", // Чуть темнее оранжевый цвет при наведении
                            },
                        }}
                        onClick={handleAddToCart}
                    >
                        Добавить в корзину
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            color: "black",
                            fontWeight: 700,
                            mt: 2,
                            backgroundColor: "#FFAA90",
                            "&:hover": {
                                backgroundColor: "#E89F50", // Чуть темнее оранжевый цвет при наведении
                            },
                        }}
                        onClick={handleClearLastOrder}
                    >
                        Удалить прошлый заказ
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default LastOrder;
