import axios from "axios";

export const sendMessage = (message: string) => {
    const TOKEN = "6541226785:AAH3P6z0bJSdI8pyt6b-vlbwq5TfKe3nAOQ";
    const CHAT_ID = "-1002041073706";
    const URL_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
    axios
        .post(URL_API, {
            chat_id: CHAT_ID,
            parse_mode: "html",
            text: message,
        })
        .catch((err) => { console.log(err) });
};