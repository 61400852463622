import React from 'react';
import bg from '../images/neitr1.webp';
import hero from '../images/hero.webp';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Hero: React.FC = () => {
    return (
        <>
            <div className="relative overflow-hidden min-h-[900px] sm:min-h-[120vh] md:min-h-screen">
                <div className="absolute inset-0" style={{ backgroundImage: `url(${bg})`, filter: 'blur(5px)' }}></div>
                <div className="absolute z-10 w-full h-full p-2 md:p-24">
                    {/* imgs */}
                    <div className='md:hidden bg-[#FCF5EC] rounded-xl p-2 w-full md:w-1/2 lg:w-fit text-center relative'>
                        <h1 className='text-2xl font-semibold'>
                            <span className='text-[#762507] '>Tort Albania</span> - Ваш оазис в мире гастрономии!
                        </h1>
                        <p className='font-medium text-base'>
                            Закажите вкуснейшую еду домашнего приготовления
                            с доставкой <br /> на дом всего в несколько кликов!
                        </p>

                        <div className='absolute bottom-[-330px] sm:bottom-[-550px] left-0 bg-[#FCF5EC] rounded-xl w-fit p-1 sm:p-2'>
                            <p className='text-sm sm:text-2xl font-semibold text-left leading-10'>
                                Ирина - главный повар <br />
                                Готовлю для Вас более 6 лет
                            </p>
                        </div>
                    </div>
                    <img className=' md:absolute bottom-0 right-10' alt='hero' src={hero} width="650px" />
                    <svg className='absolute bottom-[-1px]' width="1436" height="102" viewBox="0 0 1436 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.2795 101.823C17.7088 102.072 7.58867 102.045 0 101.823H30.2795C102.743 100.389 256.64 89.7792 476.5 54.3454C799 2.36943 1093.5 -11.6241 1242.5 9.36619C1361.7 26.1584 1443.83 78.0011 1470 101.823H30.2795Z" fill="#FCF5EC" />
                    </svg>
                    <div className='hidden md:block absolute bottom-0 w-full'>
                        <p className='absolute bottom-2 right-[10vw] lg:right-[15vw] text-2xl font-bold text-right lg:text-center lg:leading-10'>
                            Ирина - главный повар <br />
                            Готовлю для Вас более 6 лет
                        </p>
                    </div>
                    {/* texts */}
                    <div className='max-w-[1170px] mx-auto'>
                        <div className='hidden md:block bg-[#FCF5EC] rounded-xl p-2 w-full md:w-1/2 lg:w-fit text-center'>
                            <h1 className='text-2xl font-semibold'>
                                <span className='text-[#762507] '>Tort Albania</span> - Ваш оазис в мире гастрономии!
                            </h1>
                            <p className='font-medium text-base'>
                                Закажите вкуснейшую еду домашнего приготовления
                                с доставкой <br /> на дом всего в несколько кликов!
                            </p>
                        </div>
                        <div className='bg-[#FFFFFFBF] rounded-xl p-2 md:w-fit md:mt-10 md:mb-4'>
                            <h3 className='font-semibold'>
                                Наши преимущества:
                            </h3>
                            <li>
                                Самая вкусная еда на рынке славянской кухни
                            </li>
                            <li>
                                Недоступные в Албании магазинные продукты
                            </li>
                            <li>
                                Только свежие и качественные продукты
                            </li>
                            <li>
                                Бесплатная доставка в этот же день в Дурресе
                            </li>
                            <li>
                                Бесплатная доставка 3 раза в неделю по Албании
                            </li>
                            <li>
                                Лучшее обслуживание на рынке Албании
                            </li>
                        </div>
                        <Button
                            component={Link} to={`/menu`}
                            variant="contained"
                            sx={{
                                fontSize: 24,
                                color: "black",
                                fontWeight: 700,
                                mt: 2,
                                backgroundColor: "#FFAA90",
                                "&:hover": {
                                    backgroundColor: "#E89F50",
                                },
                            }}
                        >
                            посмотреть меню
                        </Button>
                    </div>
                </div>
            </div>

        </>

    );
};

export default Hero;
