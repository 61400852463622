import { Box } from "@mui/system"
import React from "react"


export default function Policy() {
    return (
        <div className="w-full bg-[#FCF5EC]">
            <div className="max-w-[1170px] mx-auto pt-4 p-2 lg:p-0">
                <h2 className='font-semibold text-3xl text-center mb-4'>Условия пользования и политика конфиденциальности</h2>
                <ol>
                    <li>
                        <strong>Основные положения:</strong> Добро пожаловать на сайт доставки еды "Tort Albania". Используя этот сайт и предоставляя свои данные, вы соглашаетесь с нижеследующими условиями пользования.
                    </li>
                    <li>
                        <strong>Предоставление информации:</strong> При заказе еды через наш сайт, вы соглашаетесь предоставить нам свои контактные данные, такие как имя, номер телефона, мессенджер и/или адрес доставки. Эти данные не будут использоваться для каких-либо других целей, кроме обработки вашего заказа и обеспечения качественного обслуживания.
                    </li>
                    <li>
                        <strong>Конфиденциальность:</strong> Мы ценим вашу конфиденциальность и обязуемся не передавать вашу личную информацию третьим лицам без вашего согласия.
                    </li>
                    <li>
                        <strong>Безопасность данных:</strong> Мы предпринимаем все необходимые меры для защиты ваших данных от несанкционированного доступа, изменения или утечки.
                    </li>
                </ol>

                <h2>Политика конфиденциальности</h2>
                <ol>
                    <li>
                        <strong>Сбор информации:</strong> Мы собираем только ту информацию, которая необходима для обработки вашего заказа и предоставления услуг доставки еды.
                    </li>
                    <li>
                        <strong>Использование информации:</strong> Ваши контактные данные будут использоваться исключительно для обработки заказа, связи с вами в случае необходимости и улучшения качества наших услуг.
                    </li>
                    <li>
                        <strong>Хранение данных:</strong> Мы не храним информацию о вас дольше, чем это необходимо для обработки заказа и выполнения наших обязательств перед вами. Как только ваш заказ будет обработан и доставлен, мы удалим вашу личную информацию из нашей системы.
                    </li>
                    <li>
                        <strong>Куки файлы:</strong> Наш сайт не использует куки файлы для отслеживания ваших действий и предпочтений. Мы не сохраняем никакую информацию на вашем устройстве без вашего разрешения.
                    </li>
                </ol>
            </div>
        </div>
    )
}