import React, { useEffect } from 'react';
import delivery from '../images/close-up-hands-holding-food-pack 1.jpg'
import GradeIcon from '@mui/icons-material/Grade';
import { Button } from '@mui/material';
import Hero from '../components/Hero';
import Promos from '../components/Promos';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { setMenu } from '../redux/menuSlice';

const Home: React.FC = () => {

    const reviews = [
        { name: "Viktoriya Kaya", stars: 5, text: "В январе 2024 снимали квартиру и заказывали борщ, сало, вареники с картошкой и блины с маком. Остались очень довольны. Всё вкусно и по-домашнему.", date: "месяц назад" },
        { name: "Car Number", stars: 5, text: "Были на отдыхе в Албании! Друзья посоветовали ТортАлбания, сказали что очень удобно готовить и еда всегда под рукой дома. Мы брали пельмени, котлеты и блины с мясом, все очень вкусно!", date: "11 месяцев назад" },
        { name: "VVM", stars: 5, text: "Готовят очень вкусно. Очень нравятся хинкали, сырники. Тортики очень вкусные. Рекомендую!!!", date: "11 месяцев назад" }
    ];

    const dispatch = useDispatch();

    useEffect(() => {
        axios
            .get(
                "https://65bb61a052189914b5bbeb61.mockapi.io/menu"
                // `https://api.jsonstorage.net/v1/json/543d57dc-bf32-4cf7-851a-9ba536178e76/873e8471-e741-44c3-aa20-583474238c4e`
            )
            .then((response) => {
                dispatch(setMenu(response.data[0]?.sections || []));
                // setMenu(response.data.sections || []);
                return response.data;
            })
            .catch((error) => {
                console.error("Error fetching menu data:", error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const menu = useSelector((state: RootState) => state.menuState.menu);
    const promoItems = menu
        .map((item) => item.items)
        .flat()
        .filter((item) => item.promoPrice);


    return (
        <>
            <Hero />
            {/* promos */}
            <div className='bg-[#FCF5EC]'>
                <div className='max-w-[1170px] mx-auto py-4 p-2'>
                    <h3 className='font-semibold text-3xl text-center'>
                        Товар недели
                    </h3>
                    <div className='p-2 sm:p-0'>
                        {
                            window.innerWidth > 1024 ? <Promos promoItems={promoItems} header='' number={3} displayAdd={false} /> : <Promos promoItems={promoItems} header='' number={4} displayAdd={false} />
                        }
                    </div>


                    <div className='p-2 md:p-0 flex flex-col md:flex-row items-center mt-4'>
                        <p className='text-xl'>
                            Наш товар регулярно пополняется и обновляется. Акционный товар появляется каждую неделю - вы можете перейти в наше меню и посмотреть его в верхней вкладке!
                        </p>
                        <Button
                            component={Link} to={`/menu`}
                            variant="contained"
                            sx={{
                                paddingX: 14,
                                fontSize: 18,
                                color: "black",
                                fontWeight: 700,
                                mt: 2,
                                backgroundColor: "#FFAA90",
                                "&:hover": {
                                    backgroundColor: "#E89F50",
                                },
                            }}
                        >
                            меню
                        </Button>
                    </div>
                </div>
            </div>
            {/* delivery */}
            <div style={{ background: 'linear-gradient(to bottom, #FCF5EC, #FFB49C)' }}>
                <div className='max-w-[1170px] mx-auto py-4 p-2'>
                    <h3 className='font-semibold text-3xl text-center '>
                        Доставка
                    </h3>
                    <div className='lg:flex lg:items-center mt-4 p-2 lg:p-0 '>
                        <img className='rounded-xl mx-auto sm:mx-0' src={delivery} alt="delivery" />
                        <ul className='mx-auto text-xl font-medium  md:p-16 leading-10'>
                            <li>
                                <span className='font-semibold'>Дуррес</span> - ежедневно
                            </li>
                            <li>
                                <span className='font-semibold'>Тирана</span> - вторник/пятница
                            </li>
                            <li>
                                <span className='font-semibold'>Саранда/Влера</span> - четверг
                            </li>
                            <li className='text-base'>
                                Оплата по факту получения, в леках/евро.
                                <br />
                                Так же возможен перевод на карту Приват Банка
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
            {/* revs */}
            <div className='w-full h-[800px] md:h-[50vh] relative ' style={{ background: 'linear-gradient(182.15deg, #FFB49C 0.58%, #FCF5EC 96.98%)' }}>
                {/* bg */}
                <div className='w-full outline-none '>
                    <svg viewBox="0 0 1440 343" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1439.99 21.1766C1389.53 19.6773 1225.2 27.0739 971.595 68.6546C654.583 120.631 365.094 134.624 218.629 113.634C101.458 96.8416 20.7222 44.9989 -4.99923 21.1766" stroke="url(#paint0_linear_25_2)" strokeWidth="10" />
                        <path d="M1439.99 48.1766C1389.53 46.6773 1225.2 54.0739 971.595 95.6546C654.583 147.631 365.094 161.624 218.629 140.634C101.458 123.842 20.7222 71.9989 -4.99923 48.1766" stroke="url(#paint1_linear_25_2)" strokeWidth="9" />
                        <path opacity="0.9" d="M1440.99 72.1766C1390.53 70.6773 1226.21 78.0739 972.597 119.655C655.585 171.631 366.096 185.624 219.631 164.634C102.46 147.842 21.7241 95.9989 -3.99727 72.1766" stroke="url(#paint2_linear_25_2)" strokeWidth="8" />
                        <path opacity="0.8" d="M1441.99 96.1766C1391.53 94.6773 1227.21 102.074 973.599 143.655C656.586 195.631 367.098 209.624 220.633 188.634C103.462 171.842 22.7261 119.999 -2.99532 96.1766" stroke="url(#paint3_linear_25_2)" strokeWidth="7" />
                        <path opacity="0.7" d="M1442.99 120.177C1392.53 118.677 1228.21 126.074 974.601 167.655C657.588 219.631 368.1 233.624 221.635 212.634C104.464 195.842 23.728 143.999 -1.99337 120.177" stroke="url(#paint4_linear_25_2)" strokeWidth="6" />
                        <path opacity="0.6" d="M1443.99 144.177C1393.53 142.677 1229.21 150.074 975.603 191.655C658.59 243.631 369.102 257.624 222.637 236.634C105.466 219.842 24.73 167.999 -0.991415 144.177" stroke="url(#paint5_linear_25_2)" strokeWidth="5" />
                        <path opacity="0.5" d="M1445 168.177C1394.43 166.677 1229.77 174.074 975.631 215.655C657.958 267.631 367.865 281.624 221.095 260.634C103.679 243.842 22.7751 191.999 -2.99999 168.177" stroke="url(#paint6_linear_25_2)" strokeWidth="4" />
                        <path opacity="0.4" d="M1446 192.177C1395.43 190.677 1230.77 198.074 976.631 239.655C658.958 291.631 368.865 305.624 222.095 284.634C104.679 267.842 23.7751 215.999 -1.99999 192.177" stroke="url(#paint7_linear_25_2)" strokeWidth="3" />
                        <path opacity="0.3" d="M1447 216.177C1396.43 214.677 1231.77 222.074 977.631 263.655C659.958 315.631 369.865 329.624 223.095 308.634C105.679 291.842 24.7751 239.999 -0.99999 216.177" stroke="url(#paint8_linear_25_2)" strokeWidth="2" />
                        <path opacity="0.2" d="M1447 240.177C1396.43 238.677 1231.77 246.074 977.631 287.655C659.958 339.631 369.865 353.624 223.095 332.634C105.679 315.842 24.7751 263.999 -0.99999 240.177" stroke="url(#paint9_linear_25_2)" />
                        <path d="M1410.21 0.178322C1422.58 -0.0729904 1432.54 -0.0454941 1440 0.178322H1410.21C1338.93 1.62703 1187.55 12.3406 971.28 48.1218C654.045 100.607 364.353 114.738 217.786 93.542C100.532 76.5851 19.7394 24.2342 -6.00012 0.178322H1410.21Z" fill="#FFB49C" />
                        <defs>
                            <linearGradient id="paint0_linear_25_2" x1="1439.99" y1="72" x2="12" y2="303" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFBAA3" />
                                <stop offset="1" stopColor="#FFDED5" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_25_2" x1="1439.99" y1="99" x2="-4.99915" y2="99" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFB59D" />
                                <stop offset="1" stopColor="#FFDED5" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_25_2" x1="1440.99" y1="123" x2="-3.99719" y2="123" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFB59D" />
                                <stop offset="1" stopColor="#FFDED5" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_25_2" x1="1441.99" y1="147" x2="-2.99524" y2="147" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFB59D" />
                                <stop offset="1" stopColor="#FFDED5" />
                            </linearGradient>
                            <linearGradient id="paint4_linear_25_2" x1="1442.99" y1="171" x2="-1.99329" y2="171" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFB59D" />
                                <stop offset="1" stopColor="#FFDED5" />
                            </linearGradient>
                            <linearGradient id="paint5_linear_25_2" x1="1443.99" y1="195" x2="-0.991333" y2="195" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFB59D" />
                                <stop offset="1" stopColor="#FFDED5" />
                            </linearGradient>
                            <linearGradient id="paint6_linear_25_2" x1="1445" y1="219" x2="-3" y2="219" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFB59D" />
                                <stop offset="1" stopColor="#FFDED5" />
                            </linearGradient>
                            <linearGradient id="paint7_linear_25_2" x1="1446" y1="243" x2="-2" y2="243" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFB59D" />
                                <stop offset="1" stopColor="#FFDED5" />
                            </linearGradient>
                            <linearGradient id="paint8_linear_25_2" x1="1447" y1="267" x2="-1" y2="267" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFB59D" />
                                <stop offset="1" stopColor="#FFDED5" />
                            </linearGradient>
                            <linearGradient id="paint9_linear_25_2" x1="1447" y1="291" x2="-1" y2="291" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFB59D" />
                                <stop offset="1" stopColor="#FFDED5" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                {/* content */}
                <div className='absolute top-0 left-0 w-full'>
                    <div className='max-w-[1170px] mx-auto py-4'>
                        <h3 className='font-semibold text-3xl text-center mb-6'>
                            Отзывы наших клиентов
                        </h3>
                        <div className='md:flex space-y-2 md:space-y-0 p-2 md:p-0 md:space-x-2 justify-around'>
                            {reviews.map((review, index) => (
                                <div key={index} className="rounded-2xl bg-white flex flex-col items-center md:w-1/4 p-2">
                                    <p><strong>{review.name}</strong></p>
                                    <div className="text-yellow-500">
                                        <GradeIcon />
                                        <GradeIcon />
                                        <GradeIcon />
                                        <GradeIcon />
                                        <GradeIcon />
                                    </div>
                                    <p>{review.text}</p>
                                </div>
                            ))}
                        </div>
                        <div className='md:flex justify-around mt-2 p-2 md:p-0'>
                            <Button
                                className='w-full md:w-[480px]'
                                component={Link} to={`/menu`}
                                variant="contained"
                                sx={{
                                    paddingX: 6,
                                    fontSize: 18,
                                    color: "black",
                                    fontWeight: 700,
                                    mt: 2,
                                    backgroundColor: "#FFAA90",
                                    "&:hover": {
                                        backgroundColor: "#E89F50",
                                    },
                                }}
                            >
                                Посмотреть нашу кухню
                            </Button>

                            <Button
                                className='w-full md:w-[480px] text-center'
                                component="a"
                                href="https://maps.app.goo.gl/kvJBvpsgHicQPF5D7"
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="contained"
                                sx={{
                                    paddingX: 6,
                                    fontSize: 18,
                                    color: "black",
                                    fontWeight: 700,
                                    mt: 2,
                                    backgroundColor: "#FFAA90",
                                    "&:hover": {
                                        backgroundColor: "#E89F50",
                                    },
                                }}
                            >
                                Смотреть все отзывы в Google Maps
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default Home;
