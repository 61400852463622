import React, { useState, useEffect } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { sendMessage } from "../functions/sendMessage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { clearCart, saveLastOrder } from "../redux/cartSlice";
import MapModal from "./MapModal";

interface OrderFormModalProps {
  open: boolean;
  onClose: () => void;
  totalAmount: number;
  setIsThanks: (arg: boolean) => void;
}

interface FormValues {
  name: string;
  phone: string;
  city: string;
  location: string;
  messenger: string;
  deliveryComment: string;
}

const OrderFormModal: React.FC<OrderFormModalProps> = ({
  open,
  onClose,
  totalAmount,
  setIsThanks,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const dispatch = useDispatch();

  const [mapModal, setMapModal] = useState(false);
  const [coords, setCoords] = useState(['', '']);

  const toggleMap = () => {
    setMapModal(!mapModal)
  }

  const cartItems = useSelector((state: RootState) => state.cart.items);

  useEffect(() => {
    if (coords[0] === '') {
      return
    }
    const latitude = coords[0];
    const longitude = coords[1];

    const mapsLink = `https://www.google.com/maps?q=${latitude},${longitude}`;
    setValue("location", mapsLink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords]);

  const onSubmit: SubmitHandler<FormValues> = (orderData) => {
    const order = cartItems
      .map((item) => `<b>${item.name}</b> ${item.quantity} шт`)
      .join(",\n");

    const message = `${orderData.name} из ${orderData.city
      } оставил заказ на вашем сайте в ${new Date().toLocaleTimeString()} на сумму <b>${totalAmount}</b> лек.\nТелефон: ${orderData.phone
      }\nМессенджер: ${orderData.messenger}\nКоординаты: ${orderData.location
      }\n${orderData.deliveryComment ? "Комментарий: " : ""}${orderData.deliveryComment ? orderData.deliveryComment : ""}\n\n<b>ЗАКАЗ:</b>\n${order}`;
    sendMessage(message);
    // console.log(message)
    // console.log(cartItems)
    dispatch(saveLastOrder());
    onClose();
    setIsThanks(true);
    dispatch(clearCart());
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'}>
      <DialogTitle className="bg-modal">
        <IconButton
          sx={{ position: "absolute", top: 0, right: 0 }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
        <Typography className="text-center px-2 pt-1" variant="h6" component="div">
          Оформление доставки
        </Typography>
      </DialogTitle>
      <DialogContent className="bg-modal">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col justify-center">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{
              required: "Обязательное поле",
              minLength: {
                value: 2,
                message: "Минимум 2 символа",
              },
            }}
            render={({ field }) => (
              <div className="relative">
                <TextField
                  label="Имя"
                  {...field}
                  error={Boolean(errors.name)}
                  fullWidth
                  margin="normal"
                />
                {errors.name && (
                  <FormHelperText
                    error={Boolean(errors.name)}
                    sx={{
                      position: "absolute",
                      right: 0,
                      bottom: "-12px",
                    }}
                  >
                    {errors.name.message}
                  </FormHelperText>
                )}
              </div>
            )}
          />

          <Controller
            name="phone"
            control={control}
            defaultValue=""
            rules={{
              required: "Обязательное поле",
              minLength: {
                value: 6,
                message: "Минимум 6 символов",
              },
              pattern: {
                value: /^[\d\s()+-]+$/,
                message: "Только цифры, скобки +, -, ",
              },
            }}
            render={({ field }) => (
              <div className="relative">
                <TextField
                  label="Албанский номер"
                  {...field}
                  error={Boolean(errors.phone)}
                  fullWidth
                  margin="normal"
                />
                {errors.phone && (
                  <FormHelperText
                    error={Boolean(errors.phone)}
                    sx={{
                      position: "absolute",
                      right: 0,
                      bottom: "-12px",
                    }}
                  >
                    {errors.phone.message}
                  </FormHelperText>
                )}
              </div>
            )}
          />

          <Controller
            name="city"
            control={control}
            defaultValue=""
            rules={{ required: "Обязательное поле" }}
            render={({ field }) => (
              <>
                <FormControl fullWidth margin="normal">
                  <InputLabel error={Boolean(errors.city)} id="city-label">
                    Город
                  </InputLabel>
                  <Controller
                    name="city"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Обязательное поле" }}
                    render={({ field }) => (
                      <div className="relative">
                        <Select
                          fullWidth
                          labelId="city-label"
                          label="Город"
                          {...field}
                          error={Boolean(errors.city)}
                        >
                          <MenuItem value={"Дуррес"}>Дуррес</MenuItem>
                          <MenuItem value={"Тирана"}>Тирана</MenuItem>
                          <MenuItem value={"Саранда"}>Саранда</MenuItem>
                          <MenuItem value={"Влера"}>Влера</MenuItem>
                        </Select>
                        <FormHelperText
                          error={Boolean(errors.city)}
                          sx={{
                            position: "absolute",
                            right: "-12px",
                            bottom: "-20px",
                          }}
                        >
                          {errors.city?.message}
                        </FormHelperText>
                      </div>
                    )}
                  />
                </FormControl>
              </>
            )}
          />

          <Controller
            name="location"
            control={control}
            defaultValue=""
            rules={{ required: "Обязательное поле" }}
            render={({ field }) => (
              <div className="relative">
                <TextField
                  label="Локация (ссылка на GMaps)"
                  {...field}
                  error={Boolean(errors.location)}
                  fullWidth
                  margin="normal"
                />
                {errors.location && (
                  <FormHelperText
                    error={Boolean(errors.location)}
                    sx={{
                      position: "absolute",
                      right: 0,
                      bottom: "-12px",
                    }}
                  >
                    {errors.location.message}
                  </FormHelperText>
                )}
              </div>
            )}
          />
          <Typography className="text-center">
            или
          </Typography>
          <Button
            variant="outlined"
            onClick={toggleMap}
          >
            Указать на карте
          </Button>

          <Controller
            name="messenger"
            control={control}
            defaultValue=""
            rules={{ required: "Обязательное поле" }}
            render={({ field }) => (
              <div className="relative">
                <p className="mb-[-12px] mt-1">
                  Оставьте, по возможности, ваш никнейм в телеграм <br /> для подтверждения заказа в формате @your_nick
                </p>
                <TextField
                  label="Мессенджер (Telegram/Whatsapp)"
                  {...field}
                  error={Boolean(errors.messenger)}
                  fullWidth
                  margin="normal"
                />
                {errors.messenger && (
                  <FormHelperText
                    error={Boolean(errors.messenger)}
                    sx={{
                      position: "absolute",
                      right: 0,
                      bottom: "-12px",
                    }}
                  >
                    {errors.messenger.message}
                  </FormHelperText>
                )}
              </div>
            )}
          />

          <Controller
            name="deliveryComment"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                label="Комментарий"
                {...field}
                error={Boolean(errors.deliveryComment)}
                fullWidth
                margin="normal"
              />
            )}
          />

        </form>
        <MapModal open={mapModal} onClose={toggleMap} setCoords={setCoords} />
      </DialogContent>
      <DialogActions className="bg-modal">
        <Button
          className="w-full"
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          sx={{
            color: "black",
            fontWeight: 700,
            backgroundColor: "#FFAA90",
            "&:hover": {
              backgroundColor: "#E89F50",
            },
          }}
        >
          Разместить заказ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderFormModal;
