import React from 'react';
import PromoCard from './PromoCard';
import { SwipeVerticalOutlined } from '@mui/icons-material';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

interface PromoItem {
    id: number;
    title: string;
    description: string;
    imageUrl: string;
}

interface Props {
    promoItems: PromoItem[];
    header: string;
    number: number;
    displayAdd: boolean;
}

const ScrollableContainer = styled(Box)({
    display: "flex",
    overflowX: "auto",
    maxWidth: "100%",
    scrollbarWidth: "thin",
    "::-webkit-scrollbar": {
        width: "8px",
    },
});

const Promos: React.FC<Props> = ({ promoItems, header = 'Акция', number = 4, displayAdd = true }) => {

    const numSlides = Math.ceil(promoItems.length / number);

    const groupedPromoItems = Array.from({ length: numSlides }, (_, index) =>
        promoItems.slice(index * number, (index + 1) * number)
    );

    const isDesktopOrLaptop = window.innerWidth >= 768;

    return (
        <>
            <div className="flex justify-between">
                <p className="text-gray-100 font-semibold mb-4 md:text-2xl">
                    {header}
                </p>
                <div className="md:hidden">
                    <SwipeVerticalOutlined />
                </div>
            </div>
            {isDesktopOrLaptop ?
                (<Carousel navButtonsAlwaysVisible className='hidden md:block'>
                    {groupedPromoItems.map((group, index) => (
                        <div className="flex flex-row justify-between flex-wrap sm:justify-around lg:justify-between sm:space-y-2 lg:space-y-0" key={index}>
                            {group.map((item) => (
                                <PromoCard item={item} key={item.id} displayAdd={displayAdd} />
                            ))}
                        </div>
                    ))}
                </Carousel>) : (
                    <ScrollableContainer aria-controls='true' className="flex gap-4 mb-4 md:hidden">
                        {promoItems.map((item) => (
                            <PromoCard item={item} key={item.id} displayAdd={displayAdd} />
                        ))}
                    </ScrollableContainer>)}
        </>
    );
};

export default Promos;
